@import "colours";

$TOOLBAR_HEIGHT: 90px;


$CONTAINER_WIDTH: 1296px;
$CONTAINER_WIDTH_INNER: 963px;

$MOBILE_BREAKPOINT: 360px;
$TABLET_BREAKPOINT: 991px;
$DESKTOP_BREAKPOINT: 1080px;

.splitBlock {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding: 45px 0;

    @media (min-width: $TABLET_BREAKPOINT) {
        flex-direction: row;
        justify-content: space-between;
    }

    .splitBlockItem {
        flex-direction: row;
        position: relative;

        > div {
            //border-radius: 18px;
            overflow: hidden;
        }

        .splitBlockItemInner {
            width: 100%;
            padding: 9px 18px;

            @media (min-width: $TABLET_BREAKPOINT) {
                display: block;
                max-width: 450px;
            }
        }

        .image {
            width: 180px;
            height: 180px;
            position: relative;

            @media (min-width: $MOBILE_BREAKPOINT) {
                width: 360px;
                height: 360px;
            }
        }

    }

}
