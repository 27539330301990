@import "styles";

@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@300;400;700&family=Newsreader:wght@600&display=swap');

* {
    box-sizing: border-box;
}

html, body, #__next {
    width: 100%;
    height: 100%;
    position: relative;
}

body {
    background-color: white;
    font-family: 'Kumbh Sans', sans-serif;
    color: #191919;
    font-size: 18px;
}

p {
    margin-bottom: 1.2em;
}


small, .font_small {
    font-size: 0.8em;
}

a {
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}




