@import "src/styles/styles";

.container {
    position: relative;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    padding-top: 98px;
    min-height: 100%;
}
